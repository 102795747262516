import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import Fuse from "fuse.js";
import orderBy from "lodash/orderBy";
import localData from "../data.json";
import { useTableSort } from "../hooks/useTableSort";
import useFilters from "../hooks/useFilters";

const FranchiseContext = createContext();

export const FranchiseProvider = ({ children }) => {
  const {
    filters,
    setFilter,
    clearFilters,
    activeFilters,
    setActiveFilters,
    IndustryCategories,
    setIndustryCategories,
    handleFilter,
    franchises,
    setFranchises,
  } = useFilters();

  const { orderedBy, requestSort, sortConfig } = useTableSort(franchises, null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const rowsPerPage = 10;

  const initialItemsToDisplay = 10;
  const [itemsDisplayed, setItemsDisplayed] = useState(initialItemsToDisplay);

  useEffect(() => {
    if (searchQuery.trim()) {
      // Options for fuse.js search
      const options = {
        threshold: 0.2, // Lower value makes the search more selective
        location: 0, // Prioritize matches near the start of the string
        distance: 100, // Consider matches within a short distance from `location`
        includeScore: true,
        // The keys to search in
        keys: ["Franchise Name"],
      };

      // Create a new instance of Fuse with the franchises list and the options
      const fuse = new Fuse(localData, options);

      // Perform the search
      const result = fuse.search(searchQuery);
      // Set the franchises state to the search result
      setFranchises(result.map((res) => res.item));
    } else {
      setFranchises(localData);
    }
  }, [searchQuery]);

  const loadMoreItems = () => {
    setItemsDisplayed(
      (prevItemsDisplayed) => prevItemsDisplayed + initialItemsToDisplay
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const nearBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 5;
      if (nearBottom) {
        setItemsDisplayed((prev) => prev + initialItemsToDisplay); // Load more items
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Your existing useEffect logic
    setTotalPages(Math.ceil(franchises.length / rowsPerPage));
  }, [franchises]);

  const sortedAndFilteredFranchises = React.useMemo(() => {
    if (sortConfig?.key) {
      // Custom sorting function
      const customSortValue = (franchise) => {
        const value = franchise[sortConfig.key];
        if (value === "-") {
          // Treat "-" as Infinity when sorting ascending and as 0 when sorting descending
          return sortConfig.direction === "ascending" ? Infinity : 0;
        }
        return value;
      };

      // Use orderBy with the custom function
      return orderBy(
        franchises,
        [customSortValue], // Pass the custom function instead of the key
        [sortConfig.direction === "ascending" ? "asc" : "desc"]
      );
    }
    setLoading(false);
    return franchises;
  }, [franchises, sortConfig]);

  const currentData = useMemo(
    () =>
      sortedAndFilteredFranchises?.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      ),
    [sortedAndFilteredFranchises, page]
  );

  const value = {
    franchises,
    setFranchises,
    loading,
    setLoading,
    page,
    setPage,
    totalPages,
    rowsPerPage,
    currentData,
    orderedBy,
    requestSort,
    sortConfig,
    sortedAndFilteredFranchises,
    searchQuery,
    setSearchQuery,
    filters,
    setFilter,
    clearFilters,
    activeFilters,
    setActiveFilters,
    IndustryCategories,
    setIndustryCategories,
    handleFilter,
    itemsDisplayed,
    loadMoreItems,
  };

  return (
    <FranchiseContext.Provider value={value}>
      {children}
    </FranchiseContext.Provider>
  );
};

export const useFranchise = () => useContext(FranchiseContext);
