import React, { useCallback, useEffect, useRef } from "react";
import {
  Table,
  TableContainer,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import localData from "./data.json";
import TableSearch from "./components/TableSearch";
import Loader from "./components/Loader";
import TableHeader from "./components/TableHeader";
import TableRows from "./components/TableRows";
import { useFranchise } from "./context/FranchiseContext";
import TableColumns from "./components/TableColumns";
import FranchiseLogoTicker from "./FranchiseLogoTicker";

const FranchiseTable = () => {
  const {
    setSearchQuery,
    loading,
    franchises,
    setFranchises,
    filters,
    clearFilters,
    IndustryCategories,
    activeFilters,
    sortedAndFilteredFranchises,
    loadMoreItems,
    itemsDisplayed,
  } = useFranchise();

  const clearAllFilters = () => {
    clearFilters();
    setSearchQuery("");
    setFranchises(localData);
  };
  const theme = useTheme(); // Using theme for consistent styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is mobile
  const tableContainerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const container = tableContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const nearBottom = scrollTop + clientHeight >= scrollHeight - 100; // Adjust as needed

    if (nearBottom) {
      loadMoreItems(); // Trigger to load more items
    }
  }, []);

  useEffect(() => {
    const container = tableContainerRef?.current;
    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const formatValue = (name, value) => {
    if (
      [
        "Initial Investment (Min)",
        "Initial Investment (Max)",
        "Franchise Fee (Min)",
        "Franchise Fee (Max)",
        "Royalty - $/Mo",
      ].includes(name)
    ) {
      value = parseFloat(value).toLocaleString();
      return `$${value}`;
    }
    if (name === "Royalty %") {
      return `${value}%`;
    }
    if (name === "Number of Franchisees") {
      value = parseFloat(value).toLocaleString();
      return `${value}`;
    }
    return value;
  };

  const displayActiveFilters = () => {
    const filterDescriptions = [];

    const addFilterDescription = (name, min, max) => {
      let description = `${name}: `;
      if (min) {
        description += `Min: ${formatValue(name, min)} `;
      }
      if (max) {
        description += `${min ? " - " : ""}Max: ${formatValue(name, max)}`;
      }
      filterDescriptions.push(<div key={name}>{description}</div>);
    };

    if (activeFilters["Number of Franchisees"]) {
      addFilterDescription(
        "Number of Franchisees",
        filters?.minLocations,
        filters?.maxLocations
      );
    }
    if (activeFilters["Year Founded"]) {
      addFilterDescription(
        "Year Founded",
        filters?.minYearFounded,
        filters?.maxYearFounded
      );
    }
    if (activeFilters["Royalty %"]) {
      addFilterDescription(
        "Royalty %",
        filters?.minRoyaltyPercent,
        filters?.maxRoyaltyPercent
      );
    }
    if (activeFilters["Initial Investment (Min)"]) {
      addFilterDescription(
        "Initial Investment (Min)",
        filters?.minInitialInvestmentMin,
        filters?.maxInitialInvestmentMin
      );
    }
    if (activeFilters["Initial Investment (Max)"]) {
      addFilterDescription(
        "Initial Investment (Max)",
        filters?.minInitialInvestmentMax,
        filters?.maxInitialInvestmentMax
      );
    }
    if (activeFilters["Initial Franchise Fee (Min)"]) {
      addFilterDescription(
        "Franchise Fee (Min)",
        filters?.minFranchiseFeeMin,
        filters?.maxFranchiseFeeMin
      );
    }
    if (activeFilters["Initial Franchise Fee (Max)"]) {
      addFilterDescription(
        "Franchise Fee (Max)",
        filters?.minFranchiseFeeMax,
        filters?.maxFranchiseFeeMax
      );
    }
    if (activeFilters["Royalty - $/Mo"]) {
      addFilterDescription(
        "Royalty - $/Mo",
        filters?.minRoyaltyCash,
        filters?.maxRoyaltyCash
      );
    }
    if (activeFilters["Industry"]) {
      const selectedCategories = Object.entries(IndustryCategories)
        .filter(([_, value]) => value)
        .map(([key]) => key)
        .join(", ");
      filterDescriptions.push(
        <div key="Industry">Industry: {selectedCategories}</div>
      );
    }

    return filterDescriptions.length > 0 ? (
      filterDescriptions
    ) : (
      <div>No active filters</div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Separate line for TableHeader */}
      <div style={{ backgroundColor: "#c2cccf" }}>
        {" "}
        {/* Add margin as needed for spacing */}
        <TableHeader
          franchises={franchises}
          displayActiveFilters={displayActiveFilters}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#c2cccf",
        }}
      >
        {/* Flex container for Search, Ticker, and Button */}
        <div
          className="container"
          style={{
            display: isMobile ? "grid" : "flex",
            gridTemplateColumns: "1fr 1fr", // Two columns, equal width
          }}
        >
          {/* Wrap TableSearch in a div to control its width without affecting its internal layout */}
          <div style={{ marginRight: 5 }}>
            <TableSearch
              localData={localData}
              setSearchQuery={setSearchQuery}
              setFranchises={setFranchises}
            />
          </div>

          {isMobile ? (
            <></>
          ) : (
            <div
              style={{
                flex: 9,
                textAlign: "center",
                maxWidth: "80%",
              }}
            >
              <FranchiseLogoTicker />
            </div>
          )}

          {/* Ensure the "Clear All Filters" button doesn't stretch and is aligned to the right */}
          <div>
            <Button
              variant="contained"
              onClick={clearAllFilters}
              style={{
                height: "40px",
                backgroundColor: "#0194d8",
                fontSize: 12,
                marginLeft: isMobile ? 15 : 0,
              }}
            >
              Clear Filters
            </Button>
          </div>
        </div>
      </div>

      {/* TableContainer adjusted for scrolling */}
      <TableContainer ref={tableContainerRef} component={Paper}>
        <Table
          stickyHeader
          className="wholeTable"
          style={{ border: 0, marginTop: 0 }}
        >
          <TableColumns style={{ border: 0 }} />
          <TableRows
            sortedAndFilteredFranchises={sortedAndFilteredFranchises.slice(
              0,
              itemsDisplayed
            )}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
export default FranchiseTable;
