import React, { memo } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import {
  IconButton,
  TableCell,
  TableHead,
  TableSortLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FilterPopover from "./FilterPopover";
import { useFranchise } from "../context/FranchiseContext";

const stickyHeaderStyle = {
  position: "sticky",
  top: 0,
  backgroundColor: "#fff",
  zIndex: 1,
};

const TableColumnsModal = () => {
  const {
    filters,
    setFilter,
    IndustryCategories,
    setIndustryCategories,
    orderedBy,
    sortConfig,
    requestSort,
    handleFilter,
    activeFilters,
  } = useFranchise();
  const openYear = Boolean(filters?.anchorElYear);
  const idYear = openYear ? "simple-popover" : undefined;
  const openLocations = Boolean(filters?.anchorElLocations);
  const idLocations = openLocations ? "simple-popover" : undefined;
  const openRoyaltyPercent = Boolean(filters?.anchorElRoyaltyPercent);
  const idRoyaltyPercent = openRoyaltyPercent ? "simple-popover" : undefined;
  const openInvestMin = Boolean(filters?.anchorElInvestMin);
  const idInvestMin = openInvestMin ? "simple-popover" : undefined;
  const openInvestMax = Boolean(filters?.anchorElInvestMax);
  const idInvestMax = openInvestMax ? "simple-popover" : undefined;
  const openFeeMin = Boolean(filters?.anchorElFeeMin);
  const idFeeMin = openFeeMin ? "simple-popover" : undefined;
  const openFeeMax = Boolean(filters?.anchorElFeeMax);
  const idFeeMax = openFeeMax ? "simple-popover" : undefined;
  const openRoyaltyCash = Boolean(filters?.anchorElRoyaltyCash);
  const idRoyaltyCash = openRoyaltyCash ? "simple-popover" : undefined;
  // const openIndustry = Boolean(filters?.anchorElIndustry);
  // const idIndustry = openIndustry ? "simple-popover" : undefined;

  const theme = useTheme(); // Using theme for consistent styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is mobile

  const clearIndustries = () => {
    const resetCategories = Object.keys(IndustryCategories).reduce(
      (acc, key) => {
        acc[key] = false; 
        return acc;
      },
      {}
    );

    setIndustryCategories(resetCategories);
  };

  const handleClickYear = (event) => {
    setFilter("anchorElYear", event.currentTarget);
  };
  const handleCloseYear = () => {
    setFilter("anchorElYear", null);
  };

  const handleClickLocations = (event) => {
    setFilter("anchorElLocations", event.currentTarget);
  };
  const handleCloseLocations = () => {
    setFilter("anchorElLocations", null);
  };

  const handleClickRoyaltyPercent = (event) => {
    setFilter("anchorElRoyaltyPercent", event.currentTarget);
  };
  const handleCloseRoyaltyPercent = () => {
    setFilter("anchorElRoyaltyPercent", null);
  };

  const handleClickInvestMin = (event) => {
    setFilter("anchorElInvestMin", event.currentTarget);
  };
  const handleCloseInvestMin = () => {
    setFilter("anchorElInvestMin", null);
  };

  const handleClickInvestMax = (event) => {
    setFilter("anchorElInvestMax", event.currentTarget);
  };
  const handleCloseInvestMax = () => {
    setFilter("anchorElInvestMax", null);
  };

  const handleClickFeeMin = (event) => {
    setFilter("anchorElFeeMin", event.currentTarget);
  };
  const handleCloseFeeMin = () => {
    setFilter("anchorElFeeMin", null);
  };

  const handleClickFeeMax = (event) => {
    setFilter("anchorElFeeMax", event.currentTarget);
  };
  const handleCloseFeeMax = () => {
    setFilter("anchorElFeeMax", null);
  };

  const handleClickRoyaltyCash = (event) => {
    setFilter("anchorElRoyaltyCash", event.currentTarget);
  };
  const handleCloseRoyaltyCash = () => {
    setFilter("anchorElRoyaltyCash", null);
  };

  const handleClickIndustry = (event) => {
    setFilter("anchorElIndustry", event.currentTarget);
  };
  const handleCloseIndustry = () => {
    setFilter("anchorElIndustry", null);
  };

  const handleCategoryChange = (event) => {
    setIndustryCategories({
      ...IndustryCategories,
      [event.target.name]: event.target.checked,
    });
  };

  const headCellData = [
    { id: "Franchise Name", label: "Franchise Name" },
    {
      id: "Auto Apply",
      label: "Auto Apply",
      noSort: true,
      className: "auto-apply-column",
    },
    { id: "Industry", label: "Industry", noSort: true },
    {
      id: "Year Founded",
      label: "Year Founded",
      filter: {
        name: "Year Founded",
        minVal: filters?.minYearFounded,
        maxVal: filters?.maxYearFounded,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElYear,
        id: idYear,
        open: openYear,
        forClick: handleClickYear,
        forClose: handleCloseYear,
      },
    },
    { id: "Company Website", label: "Site", noSort: true },
    {
      id: "Number of Franchisees",
      label: "Number of Locations",
      filter: {
        name: "locations",
        minVal: filters?.minLocations,
        maxVal: filters?.maxLocations,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElLocations,
        id: idLocations,
        open: openLocations,
        forClick: handleClickLocations,
        forClose: handleCloseLocations,
      },
    },
    {
      id: "Initial Investment (Min)",
      label: "Min Init Investment",
      filter: {
        key: "Initial Investment (Min)",
        name: "Initial Investment",
        minVal: filters?.minInitialInvestmentMin,
        maxVal: filters?.maxInitialInvestmentMin,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElInvestMin,
        id: idInvestMin,
        open: openInvestMin,
        forClick: handleClickInvestMin,
        forClose: handleCloseInvestMin,
      },
    },
    {
      id: "Initial Investment (Max)",
      label: "Max Init Investment",
      filter: {
        key: "Initial Investment (Max)",
        name: "Initial Investment",
        minVal: filters?.minInitialInvestmentMax,
        maxVal: filters?.maxInitialInvestmentMax,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElInvestMax,
        id: idInvestMax,
        open: openInvestMax,
        forClick: handleClickInvestMax,
        forClose: handleCloseInvestMax,
      },
    },
    {
      id: "Initial Franchise Fee (Min)",
      label: "Franchise Fee (Min)",
      filter: {
        key: "Initial Franchise Fee (Min)",
        name: "Franchise Fee",
        minVal: filters?.minFranchiseFeeMin,
        maxVal: filters?.maxFranchiseFeeMin,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElFeeMin,
        id: idFeeMin,
        open: openFeeMin,
        forClick: handleClickFeeMin,
        forClose: handleCloseFeeMin,
      },
    },
    {
      id: "Initial Franchise Fee (Max)",
      label: "Franchise Fee (Max)",
      filter: {
        key: "Initial Franchise Fee (Max)",
        name: "Franchise Fee",
        minVal: filters?.minFranchiseFeeMax,
        maxVal: filters?.maxFranchiseFeeMax,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElFeeMax,
        id: idFeeMax,
        open: openFeeMax,
        forClick: handleClickFeeMax,
        forClose: handleCloseFeeMax,
      },
    },
    {
      id: "Royalty %",
      label: "Royalty Percent",
      filter: {
        name: "Royalty Percent",
        minVal: filters?.minRoyaltyPercent,
        maxVal: filters?.maxRoyaltyPercent,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElRoyaltyPercent,
        id: idRoyaltyPercent,
        open: openRoyaltyPercent,
        forClick: handleClickRoyaltyPercent,
        forClose: handleCloseRoyaltyPercent,
      },
    },
    {
      id: "Royalt - $/Mo",
      label: "Royalty Cash",
      filter: {
        name: "Royalty Cash",
        minVal: filters?.minRoyaltyCash,
        maxVal: filters?.maxRoyaltyCash,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElRoyaltyCash,
        id: idRoyaltyCash,
        open: openRoyaltyCash,
        forClick: handleClickRoyaltyCash,
        forClose: handleCloseRoyaltyCash,
      },
    },
    {
      id: "FDD Link",
      label: "Link to FDD",
      noSort: true,
      className: "FDD",
    },
  ];
  return (
    <>
      <TableHead
        style={stickyHeaderStyle}
        sx={{
          borderRadius: "16px",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
          background: "linear-gradient(to bottom, #f7f7f7 0%, #f0fafa 100%)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <TableCell
            style={{
              width: "138px",
              height: "76px",
              borderBottom: "1px solid #dfdfdf",
              padding: "0",
            }}
          ></TableCell>
          {headCellData.map((headCell) => (
            <TableCell
              key={headCell.id}
              style={{
                width: "138px",
                height: "76px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontWeight: "bold",
                overflow: "hidden",
                padding: "16px",
                border: "none",
                borderBottom: "1px solid #dfdfdf",
              }}
              className={headCell.className}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column", 
                  fontSize: !isMobile ? 14 : 11,
                }}
              >
                <span>{headCell.label}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {!headCell.noSort && (
                    <TableSortLabel
                      active={orderedBy === headCell.id}
                      direction={
                        sortConfig?.key === headCell.id ? "asc" : "desc"
                      }
                      sx={{
                        "& .MuiSvgIcon-root": { color: "inherit" }, // Ensures icon inherits the text color for visibility
                      }}
                    >
                      <IconButton
                        onClick={() => requestSort(headCell.id)}
                        sx={{ padding: 0, marginLeft: 0, marginRight: 0 }}
                      >
                        <SortByAlphaIcon
                          style={{ fontSize: isMobile ? 18 : 25 }}
                        />
                      </IconButton>
                    </TableSortLabel>
                  )}
                  {headCell.filter && (
                    <>
                      <IconButton
                        onClick={headCell.filter.forClick}
                        sx={{
                          padding: 0,
                          marginLeft: "0px",
                          marginRight: "0px",
                        }} // Adjust margins to ensure icons are next to each other
                      >
                        <FilterAltIcon
                          color={
                            activeFilters[headCell.id] ? "primary" : "disabled"
                          }
                          style={{ fontSize: isMobile ? 18 : 25 }}
                        />
                      </IconButton>
                      <FilterPopover
                        headCell={headCell}
                        onFilterChange={handleFilter}
                      />
                    </>
                  )}
                </div>
              </div>
            </TableCell>
          ))}
        </div>
      </TableHead>
    </>
  );
};

export default memo(TableColumnsModal);
