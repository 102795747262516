import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import LoginPopover from "../auth/Login";

const CustomAppBar = ({
  selectedOption,
  setSelectedOption,
  navigateToDashboard,
  navigateHome,
  handleChange,
  expertSignUp,
}) => {
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{
        width: 250,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          {
            text: "Book A Franchisee",
            path: "/book-franchisee",
            name: "bookFranchisee",
          },
          {
            text: "All Franchises",
            path: "/all-franchises",
            name: "franchiseListings",
          },

          // {
          //   text: "Franchisee Reviews",
          //   path: "/franchisee-reviews",
          //   name: "reviews",
          // },
          {
            text: "Blog",
            path: "/blog",
            name: "blog",
          },
          {
            text: "Contact Us",
            path: "/site-suggestions",
            name: "feedback",
          },
        ].map((item) => (
          <ListItem
            key={item.text}
            component={NavLink}
            to={item.path}
            onClick={() => setSelectedOption(item.name)}
            activeClassName="active"
            sx={{
              color: "white",
              fontWeight: selectedOption === item.name ? "bold" : "normal",
            }}
          >
            <ListItemText
              primary={item.text}
              sx={{
                color: "white",
                fontWeight: selectedOption === item.name ? "bold" : "normal",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#0098dc",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-center",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.35)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ color: "white" }}
            onClick={navigateHome}
          >
            FranchiseNow
          </Typography>
          {!isMobile ? (
            <Typography
              variant="subtitle1"
              sx={{
                color: "white",
                marginLeft: 3.5,
                fontStyle: "italic",
              }}
            >
              One stop shop for over +1,400 franchises
            </Typography>
          ) : (
            <></>
          )}
        </Box>
        {!isMobile ? (
          <Tabs
            value={selectedOption}
            onChange={handleChange}
            sx={{
              ".MuiTabs-indicator": { backgroundColor: "white" },
              marginTop: 1.5,
              marginRight: 10,
            }}
            textColor="inherit"
            indicatorColor="secondary"
            className="tabs"
          >
            {[
              {
                text: "Book A Franchisee",
                path: "/book-franchisee",
                name: "bookFranchisee",
                className: "book-franchisee-tab",
              },

              {
                text: "All Franchises",
                path: "/all-franchises",
                name: "franchiseListings",
                className: "all-franchises-tab",
              },
              {
                text: "Blog",
                path: "/blog",
                name: "blog",
              },
              // {
              //   text: "Franchisee Reviews",
              //   path: "/franchisee-reviews",
              //   name: "reviews",
              //   className: "reviews-tab",
              // },

              {
                text: "Contact Us",
                path: "/site-suggestions",
                name: "feedback",
                className: "feedback-tab",
              },
            ].map((tab) => (
              <Tab
                key={tab.text}
                label={
                  <Tooltip
                    title={
                      tab.text === "All Franchises"
                        ? "Explore our extensive list of franchises"
                        : // : tab.text === "Franchisee Reviews"
                        // ? "Read reviews from franchisees"
                        tab.text === "Contact Us"
                        ? "Share your feedback and suggestions"
                        : tab.text === "Full Access"
                        ? "Get full access to all features"
                        : tab.text === "Book A Franchisee"
                        ? "Book A Franchisee"
                        : ""
                    }
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <span>{tab.text}</span>
                  </Tooltip>
                }
                value={tab.name} // Use the name property for the value
                className={tab.className}
                name={tab.text}
                component={NavLink}
                to={tab.path} // Use the path property for the NavLink
              />
            ))}
          </Tabs>
        ) : (
          <></>
        )}
        {!isMobile ? (
          <Typography
            variant="subtitle1"
            sx={{
              color: "#ADD8E6",
              textAlign: "left",
              fontStyle: "italic",
            }}
            onClick={expertSignUp}
          >
            Current or Former Franchisee? <br />
            <div
              style={{
                textDecoration: "underline",
                textAlign: "center",
                color: "white",
              }}
            >
              Join our Expert Network
            </div>
          </Typography>
        ) : (
          <></>
        )}
        <LoginPopover
          navigateToDashboard={navigateToDashboard}
          navigateHome={navigateHome}
        />
      </Toolbar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </AppBar>
  );
};

export default CustomAppBar;
