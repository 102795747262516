import React, { useState, memo, useCallback, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { generateUniqueId } from "../utils/generateUniqueId";
import LoginPopup from "../auth/LoginPopup";
import { useAuth } from "../auth/Auth";
import AutoApplyFormPopup from "../AutoApplyFormPopup";
import {
  fetchAppliedFranchises,
  submitFormHandling,
  AutoApplyHandling,
} from "./ApplicationHandling";
import TableColumnsModal from "./TableColumnsModal";

const TableRows = ({ sortedAndFilteredFranchises }) => {
  // State to track the selected franchise for the modal
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const { currentUser } = useAuth();
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const [formPopupOpen, setFormPopupOpen] = useState(false);
  const [currentFranchise, setCurrentFranchise] = useState(null);
  const [appliedFranchises, setAppliedFranchises] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedFranchiseForApply, setSelectedFranchiseForApply] =
    useState(null);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Other existing component logic

  const openConfirmDialog = (franchiseName) => {
    setSelectedFranchiseForApply(franchiseName);
    setConfirmDialogOpen(true);
  };

  const handleConfirmAutoApply = async () => {
    if (selectedFranchiseForApply) {
      await handleAutoApply(selectedFranchiseForApply);
      // Assuming sendConfirmationEmail is a function to send the email
    }
    setConfirmDialogOpen(false);
    setSelectedFranchiseForApply(null);
  };
  const getLogo = (franchiseName) => {
    const downloadPath = "/logos/";
    const sanitized_name = franchiseName.replace(/\//g, "").replace(/\\/g, "");
    const fileName = `${sanitized_name}.jpg`;
    return `${downloadPath}${fileName}`;
  };
  useEffect(() => {
    const initialize = async () => {
      const appliedFranchisesList = await fetchAppliedFranchises(currentUser);
      setAppliedFranchises(appliedFranchisesList);
    };
    initialize();
  }, [currentUser]);
  const handleSubmitWrapper = useCallback(
    (answers) => {
      handleSubmitForm(answers, currentFranchise);
    },
    [currentFranchise]
  );
  const handleSubmitForm = async (answers, franchiseName) => {
    submitFormHandling(
      franchiseName,
      answers,
      currentUser,
      setAppliedFranchises
    );
  };
  const handleAutoApply = async (franchiseName) => {
    AutoApplyHandling(
      franchiseName,
      currentUser,
      setAppliedFranchises,
      setLoginPopupOpen,
      setCurrentFranchise,
      setFormPopupOpen
    );
  };

  // Function to handle opening the modal with franchise details
  const handleOpenModal = (franchise) => {
    setSelectedFranchise([franchise]);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedFranchise(null);
  };
  return (
    <TableBody>
      <LoginPopup
        open={loginPopupOpen}
        onClose={() => setLoginPopupOpen(false)}
      />
      <AutoApplyFormPopup
        open={formPopupOpen}
        onClose={() => setFormPopupOpen(false)}
        onSubmit={handleSubmitWrapper}
      />
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Application</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to auto apply for {selectedFranchiseForApply}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmAutoApply} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {sortedAndFilteredFranchises?.map((franchise, index) => (
        <React.Fragment key={generateUniqueId()}>
          {/* Structured data for each franchise */}
          <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "${franchise["Franchise Name"]}",
            "image": "${franchise["Logo"]}",
            "description": "${franchise["Industry"]}",
            "url": "${franchise["Company Website"]}"
          }
          `}
          </script>
          <TableRow
            key={generateUniqueId()}
            sx={{
              bgcolor: index % 2 ? "#ebf6fe" : "#E3F2FD",
              "&:last-child td, &:last-child th": { border: 0 },
              borderBottom: "none",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: !isMobile ? 14 : 11,
              color: "darkblue",
              height: "60px",
            }}
            className="tableRowHover"
            style={{
              textAlign: "center",
              fontSize: 4,
              height: "10px",
            }}
          >
            <TableCell
              style={{
                maxWidth: "120px", // Example maxWidth, adjust as needed
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                backgroundColor: "#FFFFFF",
                borderBottom: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: !isMobile ? 14 : 11,
              }}
            >
              <img
                src={getLogo(franchise["Franchise Name"])}
                alt="Logo"
                style={{
                  width: isMobile ? "40px" : "100px", // Makes the image width fill the cell
                  height: isMobile ? "24px" : "60px", // Makes the image height fill the cell
                  objectFit: "contain", // Preserves aspect ratio and ensures image fits within the bounds
                }}
              />
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#FFFFFF",
                fontWeight: "bold",
                borderBottom: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: !isMobile ? 14 : 10,
                maxWidth: isMobile ? 80 : 10,
              }}
            >
              {franchise["Franchise Name"]}
            </TableCell>

            <TableCell
              align="center"
              style={{ backgroundColor: "#FFFFFF", borderBottom: "none" }}
            >
              {!appliedFranchises.includes(franchise["Franchise Name"]) ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0098dc", // Custom background color
                    color: "white", // Text color
                    fontSize: !isMobile ? 9 : 8,
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#0194d8", // Slightly darker on hover for a visual effect
                    },
                    minHeight: 0,
                    minWidth: isMobile ? 4 : 1,
                    padding: isMobile ? 0.5 : 1,
                    paddingLeft: isMobile ? 1.2 : 1,
                    paddingRight: isMobile ? 1.2 : 1,
                  }}
                  onClick={() => openConfirmDialog(franchise["Franchise Name"])}
                >
                  Auto Apply
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled
                  align="center"
                  style={{
                    fontSize: !isMobile ? 9 : 8,
                    fontWeight: "bold",
                    backgroundColor: "#8e9bab",
                    color: "white",
                    minHeight: 0,
                    minWidth: isMobile ? 4 : 1,
                    padding: isMobile ? 0.5 : 1,
                    paddingLeft: isMobile ? 1.2 : 1,
                    paddingRight: isMobile ? 1.2 : 1,
                  }}
                >
                  Applied
                </Button>
              )}
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                borderBottom: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: !isMobile ? 14 : 11,
                maxWidth: !isMobile ? 10 : 100,
              }}
            >
              {franchise["Industry"]}
            </TableCell>
            {isMobile ? null : (
              <TableCell
                style={{
                  width: "0px",
                  borderBottom: "none",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: !isMobile ? 14 : 11,
                  textAlign: "center",
                }}
              >
                {franchise["Year Founded"]}
              </TableCell>
            )}
            {isMobile ? null : (
              <TableCell
                style={{
                  textAlign: "center",
                  borderBottom: "none",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: !isMobile ? 14 : 11,
                }}
              >
                <IconButton
                  component="a"
                  href={franchise["Company Website"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewIcon style={{ color: "darkblue" }}></OpenInNewIcon>
                </IconButton>
              </TableCell>
            )}
            {isMobile ? null : (
              <TableCell
                style={{
                  textAlign: "center",
                  borderBottom: "none",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: !isMobile ? 14 : 11,
                }}
              >
                {franchise["Number of Franchisees"].toLocaleString()}
              </TableCell>
            )}
            {isMobile ? null : (
              <>
                {" "}
                <TableCell
                  style={{
                    width: "0px",
                    borderBottom: "none",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: !isMobile ? 14 : 11,
                    textAlign: "center",
                  }}
                >
                  {`$${franchise["Initial Investment (Min)"].toLocaleString()}`}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    borderBottom: "none",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: !isMobile ? 14 : 11,
                  }}
                >
                  {`$${franchise["Initial Investment (Max)"].toLocaleString()}`}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    borderBottom: "none",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: !isMobile ? 14 : 11,
                  }}
                >
                  {`$${franchise[
                    "Initial Franchise Fee (Min)"
                  ].toLocaleString()}`}
                </TableCell>
                <TableCell
                  style={{
                    width: "0px",
                    borderBottom: "none",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: !isMobile ? 14 : 11,
                    textAlign: "center",
                  }}
                >
                  {`$${franchise[
                    "Initial Franchise Fee (Max)"
                  ].toLocaleString()}`}
                </TableCell>
              </>
            )}
            <TableCell
              style={{
                textAlign: "center",
                borderBottom: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: !isMobile ? 14 : 11,
              }}
            >
              {isNaN(franchise["Royalty %"] * 100)
                ? "-"
                : `${(franchise["Royalty %"] * 100).toFixed(1)}%`}
            </TableCell>
            {isMobile ? null : (
              <TableCell
                style={{
                  width: "0px",
                  textAlign: "center",
                  borderBottom: "none",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: !isMobile ? 14 : 11,
                }}
              >
                {franchise["Royalt - $/Mo"]}
              </TableCell>
            )}
            <TableCell
              style={{
                backgroundColor: "#BBDEFB",
                textAlign: "center",
                borderBottom: "none",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: !isMobile ? 14 : 11,
              }}
            >
              <IconButton
                component="a"
                href={franchise["FDD Link"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileOpenIcon></FileOpenIcon>
              </IconButton>
            </TableCell>
            {/* Button to open modal with additional details */}
            {isMobile ? (
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#0098dc", // Custom background color
                    color: "white", // Text color
                    fontSize: !isMobile ? 9 : 8,
                    fontWeight: "bold",

                    "&:hover": {
                      backgroundColor: "#0194d8", // Slightly darker on hover for a visual effect
                    },
                  }}
                  onClick={() => handleOpenModal(franchise)}
                >
                  See More
                </Button>
              </TableCell>
            ) : null}
          </TableRow>
        </React.Fragment>
      ))}

      {/* Modal to display additional details */}
      <Dialog
        open={!!selectedFranchise}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        height="lg"
      >
        <TableContainer
          sx={{
            height: "300px",
            overflow: "visible",
            scrollbarWidth: "thin",
            scrollbarColor: "red",
          }}
        >
          <Table
            aria-label="customized table"
            sx={{
              display: "flex",
              justifyContent: "start",
              margin: 0,
            }}
          >
            {/* <TableColumns  /> */}
            <TableColumnsModal />
            {selectedFranchise &&
              selectedFranchise?.map((franchise, index) => (
                <div
                  key={generateUniqueId()}
                  sx={{
                    bgcolor: index % 2 ? "#ebf6fe" : "#E3F2FD",
                    "&:last-child td, &:last-child th": { border: 0 },
                    borderBottom: "none",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: !isMobile ? 14 : 11,
                    color: "darkblue",
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: 4,
                    width: "100%",
                  }}
                >
                  <TableCell
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    <img
                      src={getLogo(franchise["Franchise Name"])}
                      alt="Logo"
                      style={{
                        width: isMobile ? "40px" : "100px",
                        height: isMobile ? "24px" : "60px",
                        objectFit: "contain",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#FFFFFF",
                      fontWeight: "bold",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 10,
                    }}
                  >
                    {franchise["Franchise Name"]}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!appliedFranchises.includes(
                      franchise["Franchise Name"]
                    ) ? (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#0098dc", // Custom background color
                          color: "white", // Text color
                          fontSize: !isMobile ? 9 : 8,
                          fontWeight: "bold",
                          textWrap: "nowrap",
                          "&:hover": {
                            backgroundColor: "#0194d8", // Slightly darker on hover for a visual effect
                          },
                          minHeight: 0,
                          minWidth: isMobile ? 4 : 1,
                          padding: isMobile ? 0.5 : 1,
                          paddingLeft: isMobile ? 1.2 : 1,
                          paddingRight: isMobile ? 1.2 : 1,
                        }}
                        onClick={() =>
                          openConfirmDialog(franchise["Franchise Name"])
                        }
                      >
                        Auto Apply
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled
                        align="center"
                        style={{
                          fontSize: !isMobile ? 9 : 8,
                          fontWeight: "bold",
                          backgroundColor: "#8e9bab",
                          color: "white",
                          minHeight: 0,
                          minWidth: isMobile ? 4 : 1,
                          padding: isMobile ? 0.5 : 1,
                          paddingLeft: isMobile ? 1.2 : 1,
                          paddingRight: isMobile ? 1.2 : 1,
                        }}
                      >
                        Applied
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    {franchise["Industry"]}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                      textAlign: "center",
                    }}
                  >
                    {franchise["Year Founded"]}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    <IconButton
                      component="a"
                      href={franchise["Company Website"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <OpenInNewIcon
                        style={{ color: "darkblue" }}
                      ></OpenInNewIcon>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    {franchise["Number of Franchisees"].toLocaleString()}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                      textAlign: "center",
                    }}
                  >
                    {`$${franchise[
                      "Initial Investment (Min)"
                    ].toLocaleString()}`}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    {`$${franchise[
                      "Initial Investment (Max)"
                    ].toLocaleString()}`}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    {`$${franchise[
                      "Initial Franchise Fee (Min)"
                    ].toLocaleString()}`}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                      textAlign: "center",
                    }}
                  >
                    {`$${franchise[
                      "Initial Franchise Fee (Max)"
                    ].toLocaleString()}`}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    {isNaN(franchise["Royalty %"] * 100)
                      ? "-"
                      : `${(franchise["Royalty %"] * 100).toFixed(1)}%`}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    {franchise["Royalt - $/Mo"]}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#BBDEFB",
                      textAlign: "center",
                      border: "1px solid #dfdfdf",
                      borderBottom: "none",
                      height: "76px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: !isMobile ? 14 : 11,
                    }}
                  >
                    <IconButton
                      component="a"
                      href={franchise["FDD Link"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FileOpenIcon></FileOpenIcon>
                    </IconButton>
                  </TableCell>
                </div>
              ))}
          </Table>
        </TableContainer>
      </Dialog>
    </TableBody>
  );
};

export default memo(TableRows);
