import React, { useEffect } from "react";
import { Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom"; // Assuming you use React Router

const postURL =
  "https://us-central1-franchise-5bd11.cloudfunctions.net/app/send-email";
const contactEmail = "contact@FranchiseNow.me";

const SuccessPage = () => {
  useEffect(() => {
    const franchiseDetails = JSON.parse(
      localStorage.getItem("franchiseDetails")
    );
    if (franchiseDetails) {
      const emailData = {
        to: [franchiseDetails.franchisorEmail, contactEmail],
        subject: "Franchisee Call Requested - URGENT",
        text: `Hi ${franchiseDetails.name},\n\nA FranchiseNow client has scheduled a call with you at ${franchiseDetails.timeSlot}.\n\nPlease respond immediately to this email to confirm the booking. You will be paid $100.00 after the call. Kindly let us know if your preferred method of payment differs from what you provided via the onboarding form.\n\nBest,\nFranchiseNow Team`,
      };
      fetch(postURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          return response.text(); // Use .text() instead of .json() if the response might not be JSON
        })
        .then((data) => {
          localStorage.removeItem("franchiseDetails");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    }
  }, []);

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Thank you for your purchase.
      </Typography>
      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Back to Home
      </Button>
    </Container>
  );
};

export default SuccessPage;
