import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Utility function for debouncing
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const FranchiseSearch = ({ localData, setSearchQuery, setFranchises }) => {
  const [inputValue, setInputValue] = useState("");

  // Debounced version of the search handler
  const debouncedSearch = debounce((value) => {
    setSearchQuery(value); // Might not be necessary depending on your implementation

    if (value.trim()) {
      // Perform the search
    } else {
      setFranchises(localData);
    }
  }, 0); // Adjust debounce timing as necessary

  const handleSearch = (value) => {
    setInputValue(value);
    debouncedSearch(value);
  };
  const theme = useTheme(); // Using theme for consistent styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is mobile

  return (
    <TextField
      placeholder="Search Franchise"
      variant="outlined"
      value={inputValue}
      onChange={(e) => handleSearch(e.target.value)}
      sx={{
        width: isMobile ? 200 : 372, // Adjust width as needed
        fontSize: isMobile ? 9 : 12,
        "@media (max-width:600px)": {
          width: isMobile ? 200 : "100%",
        },

        ".MuiOutlinedInput-root": {
          height: "auto", // Let height adjust based on content and padding
          borderRadius: "25px", // More pronounced rounded corners
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
          "&.Mui-focused": {
            backgroundColor: "white",
            borderColor: "#1976d2",
          },
        },
        ".MuiInputLabel-root": {
          color: "gray",
          "&.Mui-focused": {
            color: "#1976d2",
          },
        },
        ".MuiInputBase-input": {
          padding: "10px 12px", // More generous padding for a spacious feel
          // Consider adjusting font size if necessary
        },
      }}
      // Adding a start adornment for a search icon
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={{
                color: "action.active",
                mr: isMobile ? 0 : 1,
                my: isMobile ? 0 : 0.5,
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FranchiseSearch;
