import React from "react";
import { useTheme, useMediaQuery, Box } from "@mui/material";
import "./Home.css";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const getFontSize = () => {
    if (isMobile) return 18;
    if (isLaptop) return 24; // Adjust this value for laptops
    if (isDesktop) return 36;
    return 18; // Default size
  };
  const getShapeSize = () => {
    if (isMobile) return 150;
    if (isLaptop) return 250; // Adjust this value for laptops
    if (isDesktop) return 350;
    return 18;
  };
  return (
    // <div
    //   className="home-container"
    //   style={{
    //     height: isMobile ? "100vh" : "80vh",
    //     marginTop: isMobile ? "0vh" : "5vh",
    //   }}
    // >
    //   <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfoI7vsHoyVgHp19d_YZ1Qn318ORKTf9ANsMiGM-0vIRVS0XA/viewform">
    //     <div className="section">
    //       <section
    //         className="text-section"
    //         style={{ marginBottom: isMobile ? "-10px" : 0 }}
    //       >
    //         <h1
    //           style={{
    //             fontSize: isMobile ? 18 : 36,
    //             marginBottom: isMobile ? "-10px" : 0,
    //           }}
    //         >
    //           Current or Former Franchisee?
    //         </h1>
    //         <p
    //           style={{
    //             fontSize: isMobile ? 12 : 18,
    //             fontStyle: "italic",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Join our expert network and start earning over $100/hr by sharing
    //           your experiences! Sign up in minutes!
    //         </p>
    //       </section>
    //       <Link
    //         to="https://docs.google.com/forms/d/e/1FAIpQLSfoI7vsHoyVgHp19d_YZ1Qn318ORKTf9ANsMiGM-0vIRVS0XA/viewform"
    //         className="image-link"
    //         style={{
    //           marginTop: isMobile ? "0px" : "10px",
    //           marginBottom: isMobile ? "0px" : "400px",
    //           width: isMobile ? "40%" : "100%",
    //           height: isMobile ? "40%" : "100%",
    //         }}
    //       >
    //         <img src="/reviewImage.svg" alt="Review Image" />
    //       </Link>
    //     </div>{" "}
    //   </Link>
    //   <Link to="/book-franchisee">
    //     <div className="section">
    //       <section
    //         className="text-section"
    //         style={{ marginBottom: isMobile ? "-10px" : 0 }}
    //       >
    //         <h1
    //           style={{
    //             fontSize: isMobile ? 18 : 36,
    //             marginBottom: isMobile ? "-10px" : 0,
    //           }}
    //         >
    //           Talk to a Franchise Owner
    //         </h1>
    //         <p
    //           style={{
    //             fontSize: isMobile ? 12 : 18,
    //             fontStyle: "italic",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Schedule calls with real franchisees to get the inside scoop and
    //           do real diligence
    //         </p>
    //       </section>
    //       <Link
    //         to="/book-franchisee"
    //         className="image-link"
    //         style={{
    //           marginTop: isMobile ? "0px" : "10px",
    //           marginBottom: isMobile ? "0px" : "400px",
    //           width: isMobile ? "40%" : "100%",
    //           height: isMobile ? "40%" : "100%",
    //         }}
    //       >
    //         <img src="/call.svg" alt="Schedule Calls Image" />
    //       </Link>
    //     </div>{" "}
    //   </Link>
    //   <Link to="/all-franchises">
    //     <div className="section">
    //       <section
    //         className="text-section"
    //         style={{ marginBottom: isMobile ? "-10px" : 0 }}
    //       >
    //         <h1
    //           style={{
    //             fontSize: isMobile ? 18 : 36,
    //             marginBottom: isMobile ? "-10px" : 0,
    //           }}
    //         >
    //           See All Franchises & Apply
    //         </h1>
    //         <p
    //           style={{
    //             fontSize: isMobile ? 12 : 18,
    //             fontStyle: "italic",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Auto apply to become a franchisee now! Browse our free FDD
    //           database and filter for the right franchise for you
    //         </p>
    //       </section>
    //       <Link
    //         to="/all-franchises"
    //         className="image-link"
    //         style={{
    //           marginTop: isMobile ? "0px" : "10px",
    //           marginBottom: isMobile ? "0px" : "400px",
    //           width: isMobile ? "40%" : "100%",
    //           height: isMobile ? "40%" : "100%",
    //         }}
    //       >
    //         <img src="/autoapply.webp" alt="Auto Apply Image" />
    //       </Link>
    //     </div>
    //   </Link>
    // </div>
    <>
      <div
        style={{
          height: isMobile ? "120vh" : "80vh",
          marginTop: isMobile ? "5vh" : "5vh",
          marginBottom: "2vh",
        }}
      >
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ background: "#f4f4f4" }}
        >
          <Grid item xs={12} sm={6} md={5}>
            <Typography
              variant="h4"
              align="center"
              style={{
                color: "#0098dc",
                marginBottom: "20px",
                marginTop: "10px",
                fontWeight: 700,
              }}
            >
              Want to be a business owner?
            </Typography>
            <Link to="/book-franchisee">
              <CardContent style={{ textAlign: "center" }}>
                <Avatar
                  style={{
                    margin: "0 auto",
                    backgroundColor: "#EBF8FB",
                    width: getShapeSize(),
                    height: getShapeSize(),
                    overflow: "hidden",
                  }}
                >
                  <img
                    src="/Splash_Image1.jpg"
                    alt="Business Owner"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      filter: "grayscale(100%)",
                    }}
                  />
                </Avatar>
                <Button
                  variant="contained"
                  fullWidth={isMobile ? false : true}
                  style={{
                    backgroundColor: "#0098dc",
                    color: "#fff",
                    marginBottom: "10px",
                    marginTop: "10px",
                    padding: "10px 20px",
                    borderRadius: "20px",
                    fontWeight: 600,
                    fontSize: isMobile ? "14px" : "24px",
                    maxWidth: isMobile ? 300 : 600,
                  }}
                >
                  Talk to a Franchise Owner
                </Button>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  sx={{
                    fontStyle: "italic",
                    fontSize: getFontSize(),
                    mt: 3,
                    lineHeight: 1.3,
                  }}
                >
                  Schedule calls with real franchisees to get the inside scoop
                  and get started on your entrepreneurial journey
                </Typography>
              </CardContent>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Typography
              variant="h4"
              align="center"
              style={{
                color: "#0098dc",
                marginBottom: "20px",
                marginTop: "10px",
                fontWeight: 700,
              }}
            >
              Already a franchisee?
            </Typography>
            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfoI7vsHoyVgHp19d_YZ1Qn318ORKTf9ANsMiGM-0vIRVS0XA/viewform">
              <CardContent style={{ textAlign: "center" }}>
                <Avatar
                  style={{
                    margin: "0 auto",
                    backgroundColor: "#EBF8FB",
                    width: getShapeSize(),
                    height: getShapeSize(),
                    overflow: "hidden",
                  }}
                >
                  <img
                    src="/image2.png"
                    alt="Franchisee"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      filter: "grayscale(100%)",
                    }}
                  />
                </Avatar>

                <Button
                  variant="contained"
                  fullWidth={isMobile ? false : true}
                  style={{
                    backgroundColor: "#0098dc",
                    color: "#fff",
                    marginBottom: "10px",
                    marginTop: "10px",
                    padding: "10px 20px",
                    borderRadius: "20px",
                    fontWeight: 600,
                    fontSize: isMobile ? "14px" : "24px",
                    maxWidth: isMobile ? 300 : 600,
                  }}
                >
                  Join the Expert Network
                </Button>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  sx={{
                    fontStyle: "italic",
                    fontSize: getFontSize(),
                    mt: 3,
                    lineHeight: 1.3,
                  }}
                >
                  Join our expert network and start earning +$100/hour just by
                  sharing your experiences – sign up only takes minutes
                </Typography>
              </CardContent>
            </Link>
          </Grid>
        </Grid>

        <Box
          mt={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={4}
        >
          <Link to="/all-franchises" style={{ textDecoration: "none" }}>
            <Typography
              variant="body1"
              sx={{
                color: "#0098dc",
                "&:hover": {
                  color: "#005f99",
                },
              }}
            >
              Explore Franchisors
            </Typography>
          </Link>
          <Link to="/blog" style={{ textDecoration: "none" }}>
            <Typography
              variant="body1"
              sx={{
                color: "#0098dc",
                "&:hover": {
                  color: "#005f99",
                },
              }}
            >
              Blog
            </Typography>
          </Link>
          <Link to="/site-suggestions" style={{ textDecoration: "none" }}>
            <Typography
              variant="body1"
              sx={{
                color: "#0098dc",
                "&:hover": {
                  color: "#005f99",
                },
              }}
            >
              Contact Us
            </Typography>
          </Link>
        </Box>
      </div>
    </>
  );
};

export default Home;
